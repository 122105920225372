import React, { useState } from 'react'
import { 
    Nav, 
    NavItem, 
    NavLink, 
    TabContent, 
    TabPane, 
    Row, 
    Col, 
    Card, 
    CardTitle, 
    CardText, 
    Button, 
    Container,
    CardBody, 
    CardSubtitle
} from 'reactstrap'

const dataRender = [
    
    {
        nombre:'Masterplan 360 renderizado ',
        tipo:'Render 360º',
        id:'santa-cruz',
        image:'assets/portafolio/santacruz.jpg',
        url:'https://www.lanube360.com/santacruz/'
    },
    {
        nombre:'Piloto Virtual',
        tipo:'Render 360º',
        id:'1',
        image:'assets/portafolio/render360/link1.jpg',
        url:'https://www.lanube360.com/terranostra/one-loft/'
    },
    {
        nombre:'Piloto Virtual',
        tipo:'Render 360º',
        id:'2',
        image:'/assets/portafolio/render360/link2.jpg',
        url:'https://www.lanube360.com/puerto-velero/tour.html'
    },
    {
        nombre:'Futuro Quincho:',
        tipo:'Render 360º',
        id:'3',
        image:'assets/portafolio/render360/link3.jpg',
        url:'https://www.lanube360.com/quincho'
    },
    {
        nombre:'Futuro Portón',
        tipo:'Render 360º',
        id:'4',
        image:'assets/portafolio/render360/link4.jpg',
        url:'https://www.lanube360.com/porton'
    },
    {
        nombre:'Espacios Comunes',
        tipo:'Render 360º',
        id:'5',
        image:'assets/portafolio/render360/link5.jpg',
        url:'https://www.lanube360.com/bricsa/espacios-comunes/'
    },
    {
        nombre:'Futuro Muelle',
        tipo:'Render 360º',
        id:'6',
        image:'assets/portafolio/render360/link6.jpg',
        url:'https://www.lanube360.com/chepu-tour/'
    }
];
const dataTourAereo = [
    {
        nombre:'Aéreo con render y 4 vistas reales',
        tipo:'Tour 360',
        id:'1',
        image:'assets/portafolio/touraereo/link1.jpg',
        url:'https://www.lanube360.com/ecasa/brisas-azapa/'
    },
    {
        nombre:'Completo',
        tipo:'Tour Aéreo',
        id:'2',
        image:'assets/portafolio/touraereo/link2.jpg',
        url:'https://www.lanube360.com/edificio27/'
    },
    {
        nombre:'Con accesos y menú de servicios:',
        tipo:'Tour Aéreo',
        id:'3',
        image:'assets/portafolio/touraereo/link3.jpg',
        url:'https://www.lanube360.com/tour-vista-portezuelo/'
    },
    {
        nombre:'Con render',
        tipo:'Tour 360',
        id:'4',
        image:'assets/portafolio/touraereo/link4.jpg',
        url:'https://www.lanube360.com/indesa/europa/'
    },
    {
        nombre:'vista real',
        tipo:'Tour 360',
        id:'5',
        image:'assets/portafolio/touraereo/link4.jpg',
        url:'https://www.lanube360.com/ecasa/edificio-bezanilla/'
    },
    {
        nombre:'Con render y 3 vistas reales:',
        tipo:'Tour Aéreo',
        id:'6',
        image:'assets/portafolio/touraereo/link5.jpg',
        url:'https://www.lanube360.com/indesa/inmacul-vistas/'
    },
]
const data = [
    {
        nombre:'Guanay con cotizador',
        tipo:'Masterplan 360',
        id:'guanay',
        image:'assets/portafolio/guanay.jpg',
        url:'https://www.lanube360.com/guanay-etapa2-3/'
    },
    {
        nombre:'Reserva Frutillar Etapas',
        tipo:'Masterplan 360',
        id:'reserva-frutillar',
        image:'assets/portafolio/reserva-frutillar-etapas.jpg',
        url:'https://www.lanube360.com/reservafrutillar-2025/'
    },
    {
        nombre:'Quinta El Olivo',
        tipo:'Masterplan 360',
        id:'quinta-el-olivo',
        image:'assets/portafolio/quintaolivo.jpg',
        url:'https://www.lanube360.com/quinta-el-olivo/'
    },
    {
        nombre:'Reserva Los Carpinteros',
        tipo:'Masterplan 360',
        id:'1',
        image:'assets/portafolio/carpintero.jpg',
        url:'https://lanube360.com/carpinteros/'
    },

    {
        nombre:'Las Cavas Tahona',
        tipo:'Masterplan 360',
        id:'2',
        image:'assets/portafolio/link2.jpg',
        url:'https://www.lanube360.com/las-cavas-tahona/'
    },
    {
        nombre:'Altavista Curuguaty',
        tipo:'Masterplan 360',
        id:'3',
        image:'assets/portafolio/link3.jpg',
        url:'https://www.lanube360.com/py/altavista-curuguaty/'
    },
    {
        nombre:'Puerto Rio Blanco',
        tipo:'Masterplan 360',
        id:'4',
        image:'assets/portafolio/puerto-rio-blanco.jpg',
        url:'https://lanube360.com/puerto-rio-blanco/'
    },
    // {
    //     nombre:'Fracción Jardines Oviedo',
    //     tipo:'Masterplan 360',
    //     id:'4',
    //     image:'assets/portafolio/link4.jpg',
    //     url:'https://www.lanube360.com/py/fraccion-jardines-oviedo/'
    // },
    {
        nombre:'Don rodolfo',
        tipo:'Masterplan 360',
        id:'5',
        image:'assets/portafolio/link5.jpg',
        url:'https://www.lanube360.com/py/don-rodolfo/'
    },
    // {
    //     nombre:'Nueva Asunción Centro',
    //     tipo:'Masterplan 360',
    //     id:'6',
    //     image:'assets/portafolio/link6.jpg',
    //     url:'https://www.lanube360.com/py/nueva-asuncion-centro/'
    // },
    {
        nombre:'Vista Salto',
        tipo:'Masterplan 360',
        id:'7',
        image:'assets/portafolio/link7.jpg',
        url:'https://www.lanube360.com/py/vista-salto/'
    },
    {
        nombre:'San Marcos',
        tipo:'Masterplan 360',
        id:'8',
        image:'assets/portafolio/link8.jpg',
        url:'https://www.lanube360.com/py/san-marcos/'
    },
    {
        nombre:'Hacienda Liray',
        tipo:'Masterplan 360',
        id:'9',
        image:'assets/portafolio/link9.jpg',
        url:'https://www.lanube360.com/hacienda-liray/'
    },
    {
        nombre:'Hacienda Lomas',
        tipo:'Masterplan 360',
        id:'10',
        image:'assets/portafolio/link10.jpg',
        url:'https://www.lanube360.com/hacienda-lomas/'
    },
    {
        nombre:'Altopite',
        tipo:'Masterplan 360',
        id:'11',
        image:'assets/portafolio/link11.jpg',
        url:'https://www.lanube360.com/altopite/'
    },
    {
        nombre:'Polcura',
        tipo:'Masterplan 360',
        id:'12',
        image:'assets/portafolio/link12.jpg',
        url:'https://www.lanube360.com/polcura/'
    },
    // {
    //     nombre:'Brisas Villeta',
    //     tipo:'Masterplan 360',
    //     id:'13',
    //     image:'assets/portafolio/link13.jpg',
    //     url:'https://www.lanube360.com/py/brisas-villeta/'
    // },
    {
        nombre:'Santa Carolina',
        tipo:'Masterplan 360',
        id:'14',
        image:'assets/portafolio/link14.jpg',
        url:'https://www.lanube360.com/py/santa-carolina/'
    },
    // {
    //     nombre:'Bellavista Pilar',
    //     tipo:'Masterplan 360',
    //     id:'15',
    //     image:'assets/portafolio/link15.jpg',
    //     url:'https://lanube360.com/bellavista-pilar/'
    // },
]
export const Portafolio = () => {
    const [tabActive, setTabActive] = useState("1")
    const handleOnClick = (i) => {
        setTabActive(i)
    }
  return (
    <div id="portafolio" className='section section-feature cd-section'>
        <Container>
            <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Portafolio</h2>
            </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column pb-3">
                    <Nav className="pb-4 justify-content-center" tabs >
                        <NavItem>
                            <NavLink
                            className={tabActive === '1'? 'active' : ''}
                            onClick={() => handleOnClick("1")}
                            >
                            Masterplan 360
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={tabActive === '2'? 'active' : ''}
                            onClick={() => handleOnClick("2")}
                            >
                            Tour Aéreo
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={tabActive === '3'? 'active' : ''}
                            onClick={() => handleOnClick("3")}
                            >
                            Render 360
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent  activeTab={tabActive}>
                        <TabPane tabId="1">
                            <Row>
                                { data.map( item => (
                                    <Col key={item.id} sm="4">
                                        <Card
                                            style={{ width: '100%', padding:'1rem' }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={item.image}
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {item.nombre}
                                                </CardTitle>
                                                <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                                >
                                                {item.tipo}
                                                </CardSubtitle>
                                                <CardText>
                                                {/* Some quick example text to build on the card title and make up the bulk of the card‘s content. */}
                                                </CardText>
                                                <Button onClick={() => window.open(item.url, '_blank')}>
                                                    Ver Más
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                        <Row>
                           { dataTourAereo.map( item => (
                                    <Col key={item.id} sm="4">
                                        <Card
                                            style={{ width: '100%', padding:'1rem' }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={item.image}
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {item.nombre}
                                                </CardTitle>
                                                <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                                >
                                                {item.tipo}
                                                </CardSubtitle>
                                                <CardText>
                                                {/* Some quick example text to build on the card title and make up the bulk of the card‘s content. */}
                                                </CardText>
                                                <Button onClick={() => window.open(item.url, '_blank')}>
                                                    Ver Más
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                           </Row>
                        </TabPane>
                        <TabPane tabId="3">
                           <Row>
                           { dataRender.map( item => (
                                    <Col key={item.id} sm="4">
                                        <Card
                                            style={{ width: '100%', padding:'1rem' }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={item.image}
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {item.nombre}
                                                </CardTitle>
                                                <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                                >
                                                {item.tipo}
                                                </CardSubtitle>
                                                <CardText>
                                                {/* Some quick example text to build on the card title and make up the bulk of the card‘s content. */}
                                                </CardText>
                                                <Button onClick={() => window.open(item.url, '_blank')}>
                                                    Ver Más
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                           </Row>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>
  </div>
  )
}
