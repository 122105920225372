import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap'
import MapPin from 'components/Icons/MapPin'
import Drone2 from 'components/Icons/Drone2'
import VrHeadset from 'components/Icons/VrHeadset'

export const Servicios = () => {
  return (
    <div id="servicios" className='section section-feature cd-section'>
        <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center pb-3" md="8">
            <h2 className="title">Servicios</h2>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div >
              <div className='text-center'>
                <MapPin width="48" height="48"/>

              </div>
              <div className="description">
                <h4 className="info-title text-center pb-3">Masterplan 360 Loteos</h4>
                <Row style={{paddingTop:16, paddingBottom:42}}>
                  <Col className="d-flex pb-sm-3">
                    <Row>
                      <Col xs="12">
                        <p style={{textAlign:'justify', alignContent:'center', padding:'0.75rem'}}>
                          Masterplan 360 es una herramienta innovadora que utiliza vistas aéreas en 360° para ofrecer 
                          recorridos virtuales de proyectos de Loteos. Permite a los clientes explorar el proyecto en detalle, 
                          conocer la distribución y la información específica de cada lote, visualizar las áreas comunes
                          y el paisaje que lo rodea. Además incluye puntos de interés cercanos lo que ayuda a resaltar 
                          el valor del proyecto. <br/><br/>Esta experiencia interactiva no solo facilita la toma de decisiones, 
                          sino que también mejora la presentación del proyecto de manera atractiva y efectiva.

                        </p>
                      
                      </Col>
        
                      
                      
                    </Row>
                    
                  </Col>
                  
                  <Col md="6">
                    <ListGroup flush>
                        <ListGroupItem style={{borderTop:0, paddingTop:0}}>
                            <p><b>Dinámica y actualizada:</b> Vincula tu Masterplan 360 con tu CRM o actualiza el estado o información de los lotes en tiempo real desde nuestra plataforma.
                            </p>
                        </ListGroupItem>
                        <ListGroupItem>
                            <p><b>Impulsa tus ventas:</b> Un Masterplan 360 ofrece toda la información esencial que un posible cliente necesita para tomar decisiones de compra informadas.</p>
                        </ListGroupItem>
                        <ListGroupItem>
                            <p><b>Genera Leads:</b> Generación de acciones como botón WhatsApp o formulario de contacto que lleven al visitante a requerir más información.</p>
                        </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </div>
              <Row>
          <Col xs="12" className="d-flex justify-content-center pb-5">
            <Button to="/#portafolio" tag={Link}>Ver Ejemplos</Button>
          </Col>
          </Row>
            </div>
          </Col>
          <Col md="6">
            <div>
              <div className='text-center'>
                <Drone2 width="48" height="48"/>
              </div>
              <div className='description'>
                <h4 className="info-title text-center pb-3" >Tour Aeréo Inmobilirias</h4>
                <ListGroup flush>
                      <ListGroupItem style={{borderTop:0}}>
                        <p>Permite visualizar el proyecto y su entorno en 360º.</p>
                      </ListGroupItem>
                      <ListGroupItem>
                          <p>Se destacan puntos de interés y conectividad.</p>
                      </ListGroupItem>
                      <ListGroupItem>
                          <p>Futuras vistas para proyectos en altura.</p>
                      </ListGroupItem>
                      <ListGroupItem style={{borderTop:'1px !important'}}>
                          <p>Herramienta para compartir por RRSS y visualizar en pantallas Touch, páginas web y móviles.</p>
                      </ListGroupItem>
                  </ListGroup>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div>
              <div className='text-center'>
                <VrHeadset width="48" height="48"/>

              </div>
              <div className="description">
                <h4 className="info-title text-center pb-3">Render 360° Piloto Virtual y Espacios Comunes</h4>
                <ListGroup flush>
                    <ListGroupItem style={{borderTop:0}}>
                        <p>Render en 360º para proyectos en verde.</p>
                    </ListGroupItem>
                    <ListGroupItem>
                        <p>Se inserta el mapa de navegación y se conectan vistas 360º entre sí.</p>
                    </ListGroupItem>
                    <ListGroupItem>
                      <p>Aplicación compatible con lentes VR, compartir por RRSS y visualizar en pantallas Touch, páginas web y móviles.</p>
                    </ListGroupItem>
                </ListGroup>
              </div>
            </div>
          </Col>
        </Row>
        
      </Container>
    </div>
  )
}
